import * as React from "react"
import { Button } from "react-bootstrap"

const Quote = () => {
  return (
    <div className="section no-border">
      <div className="contentWrap">
        <Button className="actionButton black" variant="outline-dark" href="https://mailchi.mp/6c5190ea49bc/website-price-calculator">Website Preis Berechnen</Button>
      </div>
    </div>
  )
}

export default Quote
