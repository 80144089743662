import * as React from "react"
import * as missionStyles from "./mission.module.css"
import Video from "../videos/paint-video.mp4"
import Button from "react-bootstrap/Button"

const Mission = () => {
  return (
    <div className={missionStyles.missionBanner}>
      <div className={missionStyles.logo}>
        <h2>Altergott Studios</h2>
      </div>

      {/*<video loop muted autoPlay playsInline className={missionStyles.video}>
        <source src={Video} type="video/mp4" />
      </video>*/}
      <div className={missionStyles.missionQuote}>
        <h2>
          "Unsere Mission ist es, unseren Kunden zu helfen, ihre Marketingziele
          zu erreichen, indem wir effektive und markentreue Websites erstellen."
          <br></br>- Nikolai Altergott
        </h2>
      </div>
      <Button className="actionButton" variant="light" href="https://mailchi.mp/6c5190ea49bc/website-price-calculator">Website Preis Berechnen</Button>
    </div>
  )
}

export default Mission
