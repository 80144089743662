import * as React from "react"
import * as servicesStyles from "./services.module.css"

const Services = () => {
  return (
    <div className="section">
    <div className="contentWrap">
      <h2 className="center">Leistungen</h2>
      <div className={servicesStyles.serviceGrid}>
        <div>
          <ul className={servicesStyles.servicesList}>
            <li>Planung, Gestaltung und Entwicklung von Websites</li>
          </ul>
        </div>
        <div>
          <ul className={servicesStyles.servicesList}>
            <li>Suchmaschinenoptimierung (SEO)</li>
          </ul>
        </div>
        <div>
          <ul className={servicesStyles.servicesList}>
            <li>Rechtliche Absicherung von Websites (DSVGO, Impressum)</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Services
