import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Mission from "../components/mission"
import Services from "../components/services"
import Work from "../components/work"
import Principles from "../components/principles"
import Process from "../components/process"
import Footer from "../components/footer"
import Contact from "../components/contact"
import Quote from "../components/quote"

const IndexPage = () => (
  <div>
  <Seo title="Altergott Studios - Webdesign Agentur Heilbronn" lang="de" description="Wir entwickeln effektive, marken-treue und erschwingliche Websites. Jetzt ein kostenloses Angebot anfordern!" />
  <Mission />
    <Services />
    <Work />
    <Principles />
    <Process />
    <Quote />
    <Footer />
    
  </div>
)

export default IndexPage
