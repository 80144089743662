import * as React from "react"
import * as processStyles from "./process.module.css"
import Tab from "react-bootstrap/Tab"
import Nav from "react-bootstrap/Nav"

const Process = () => {
  return (
    <div className="section">
    <div className="contentWrap">
      <h2 className="center">Prozess</h2>
      <div className={processStyles.slider}>
      <div className={processStyles.slides}>
        <div>
          <h3>1. Gespräch</h3>
          <p>
            Wir kommen ins Gespräch über Ihre Vorstellungen, Ihr Unternehmen,
            Ihre Ziele. Sie stellen uns Fragen, die Sie haben, und wir beraten
            Sie.
          </p>
        </div>
        <div>
          <h3>2. Angebot</h3>
          <p>
            Wir schicken Ihnen ein Angebot für Ihre angeforderten Leistungen.
          </p>
        </div>
        <div>
          <h3>3. Planung</h3>
          <p>
            Wir schicken Ihnen die ersten Pläne der Website und Sie geben uns
            Rückmeldung.
          </p>
        </div>
        <div>
          <h3>4. Inhaltssammlung</h3>
          <p>
            Sie überliefern uns besprochene Texte, Fotos und andere Inhalte.
          </p>
        </div>
        <div>
          <h3>5. Gestaltung</h3>
          <p>
            Wir erstellen Entwürfe der Website samt Farben, Schriftzug, Logo und
            Fotos.
          </p>
        </div>

        <div>
          <h3>6. Entwicklung</h3>
          <p>
            Sie bekommen erste Prototypen der Website zu sehen und geben uns
            Rückmeldung.
          </p>
        </div>
        <div>
          <h3>7. Veröffentlichung</h3>
          <p>
            Die Website wird zusammen besprochen, ein Domain wird registriert
            und die Website wird veröffentlicht.
          </p>
        </div>
        <div>
          <h3>8. Wartung</h3>
          <p>
            Auf Anfrage übernehmen wir gewünschte Änderungen an der Website.
          </p>
        </div>
        </div>
      </div>
      {/*<div className={processStyles.scrollGuide}>
              <p>Nach unten scrollen</p>
            </div>*/}

      {/*<Tab.Pane eventKey="second">
            <div className={processStyles.processWindow}>
              <div>
                <h3>1. Gespräch</h3>
                <p>Wir besprechen Ihre Vorstellungen, Ihr Unternehmen und Ihre Ziele. Sie stellen uns Fragen, die Sie haben und wir beraten Sie.</p>
              </div>
              <div>
                <h3>2. Untersuchung der Markenidentität und Markenwerte</h3>
                <p>Wir stellen Ihnen Frage über Ihr Unternehmen und dessen Marke, um mehr über die Wahrnehmung Ihrer Marke zu verstehen.</p>
              </div>
              <div>
                <h3>3. Erstellung eines neuen Corporate Design</h3>
                <p>Unter Berücksichtigung der gewonnenen Kenntnisse über Ihre Markenidentität und Markenwerte und mit Ihrem Input, entwerfen wir ein neues Corporate Design, welches aus Logo, Schriftzug, Designelementen und Farbkombinationen besteht.</p>
              </div>
              <div>
                <h3>4. Übergabe</h3>
                <p>Wie übergeben Ihnen die Dateien, die zum neuen Corporate Design gehören sowie einen Markenleitfaden, welcher alle Komponente Ihres neuen Corporate Design dokumentiert und erklärt.</p>
              </div>
            </div>
            <div className={processStyles.scrollGuide}>
              <p>Nach unten scrollen</p>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="third">
            <div className={processStyles.processWindow}>
              <div>
                <h3>Gespräch</h3>
                <p>Wir reden über Ihre Vorstellungen, Anforderungen und Ziele. Sie können uns Fragen stellen und wir beraten Sie.</p>
              </div>
              <div>
                <h3>Gestaltung</h3>
                <p>Wir gestalten das Werbematerial mit Ihrem Feedback.</p>
              </div>
              <div>
                <h3>Übergabe</h3>
                <p>Wir überliefern Ihnen das Werbematerial samt den dazugehörenden Dateien.</p>
              </div>
            </div>
            <div className={processStyles.scrollGuide}>
              <p>Nach unten scrollen</p>
            </div>
          </Tab.Pane>*/}
    </div>
    </div>
  )
}

export default Process
