// extracted by mini-css-extract-plugin
export var work = "work-module--work--2dtP3";
export var slider = "work-module--slider--1RC3L";
export var workGrid = "work-module--workGrid--2fuhG";
export var workElement = "work-module--workElement--34fSh";
export var link = "work-module--link--1XOsq";
export var flexbar = "work-module--flexbar--3S2hH";
export var navItem = "work-module--navItem--XbYD2";
export var videoWrapper = "work-module--videoWrapper--2_r3A";
export var video = "work-module--video--nCuKu";
export var projectName = "work-module--projectName--3m-Bf";
export var info = "work-module--info--3erun";
export var project = "work-module--project--2dE12";
export var one = "work-module--one--3f63K";
export var two = "work-module--two--zD0U5";