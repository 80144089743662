import * as React from "react"
import * as principlesStyles from "./principles.module.css"

const Principles = () => {
  return (
    <div className="section">
      <div className="contentWrap">
        <h2 className="center">Prinzipien</h2>
        <div>
          <ol className={principlesStyles.principlesList}>
            <text>
              <li>
                <strong>
                  Wir erstellen wunderschöne und einzigartige Erfahrungen,
                </strong>{" "}
                welche die Marke unseres Kunden wiederspiegeln und stärken.
              </li>
            </text>
            <text>
              <li>
                <strong>
                  Wir verstehen uns als Partner für den Erfolg unseres Kunden
                </strong>{" "}
                und geben daher alles, um dem Kunden bei dem Erreichen seiner
                Ziele zu unterstützen.
              </li>
            </text>
            <text>
              <li>
                <strong>Wir hören dem Kunden zu</strong> und erarbeiten ein
                tiefes Verständnis für ihn, um ihn effektiv zu beraten sowie
                markentreue Erfahrungen zu gestalten.
              </li>
            </text>
            <text>
              <li>
                <strong>
                  Wir nutzen die neuesten und besten Technologien,
                </strong>{" "}
                um die benutzerfreundlichsten Erfahrungen zu schaffen.
              </li>
            </text>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default Principles
