import * as React from "react"
import * as workStyles from "./work.module.css"
import { Link } from "gatsby"
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const renderTooltip = (props) => (
  <Tooltip className="tooltip" {...props}>
    Simple tooltip
  </Tooltip>
);

const Work = () => {
  return (
    <div className="section">
    <div className="contentWrap">
      <h2 className="center">Arbeit</h2>
      <div className={workStyles.slider}>
        <div className={workStyles.workGrid}>
          <div className={workStyles.workElement} id="slide-1">
            <Link to="/naturrepublik" title="naturrepublik">
                <div className={workStyles.project}>
                <div className={workStyles.projectName}>
                  <h3>Naturrepublik</h3>
                  <p class={workStyles.info}>Projekt anschauen</p>
                </div>
                </div>
            </Link>
          </div>
          <div className={workStyles.workElement} id="slide-2">
            <Link to="/gasthauslinde">
            <div  className={`${workStyles.project} ${workStyles.one}`}>
                <div className={workStyles.projectName}>
                  <h3>Gasthaus Linde</h3>
                  <p class={workStyles.info}>Projekt anschauen</p>
                </div>
                </div>
            </Link>
          </div>
          <div className={workStyles.workElement} id="slide-3">
            <Link to="/pluto">
            <div  className={`${workStyles.project} ${workStyles.two}`}>
                <div className={workStyles.projectName}>
                  <h3>Pluto Technologies Limited</h3>
                  <p class={workStyles.info}>Projekt anschauen</p>
                </div>
                </div>
            </Link>
          </div>
        </div>

        {/*<Tab.Pane eventKey="second">
              <div className={workStyles.workGrid}>
                <Link to="/altergott-webdesign-marke">
                  <div className={workStyles.project}>
                  <div className={workStyles.projectName}>
                      <h3>Altergott Webdesign GbR</h3>
                    </div>
                  </div>
                  
                </Link>
                <Link to="/pluto-marke">
                  <div className={`${workStyles.project} ${workStyles.one}`}>
                  <div className={workStyles.projectName}>
                      <h3>Pluto Technologies Limited</h3>
                    </div>
                  </div>
                  
                </Link>
                
                
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <div className={workStyles.workGrid}>
              <Link to="/altergott-webdesign-werbe">
                  <div className={workStyles.project}>
                  <div className={workStyles.projectName}>
                      <h3>Altergott Webdesign GbR</h3>
                    </div>
                  </div>
                  
                </Link>
                
              </div>
            </Tab.Pane>*/}
      </div>
    </div>
    </div>
  )
}

export default Work
